import type { OnInit } from '@angular/core';
import { EventEmitter, Inject, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import type { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import type { SubmittableFormGroup } from '../../generic/submittable-form-group';
import type { Stop } from 'src/app/transit/shared/gtfs/stop';
import { GtfsService } from 'src/app/transit/shared/gtfs/gtfs.service';

@Component({
  selector: 'mtx-stop-autocomplete',
  templateUrl: './stop-autocomplete.component.html',
  styleUrls: ['./stop-autocomplete.component.scss'],
  standalone: false,
})
export class StopAutocompleteComponent implements OnInit {

  @Input() public translateText?: string;

  @Input() public fgName?: string;

  @Input() public stopObj?: Stop;

  @Output() public markAsTouched = new EventEmitter<void>();

  @Output() public stopAdded = new EventEmitter<Stop>();

  public stopModel?: Stop;

  public reqValFn = Validators.required;

  public constructor(@Inject(ControlContainer) private readonly controlContainer: ControlContainer,
    @Inject(GtfsService) private readonly stopService: GtfsService) {}

  public get fg(): SubmittableFormGroup | null {
    return this.fgName ? (this.controlContainer.control?.get(this.fgName) as SubmittableFormGroup) : null;
  }

  public ngOnInit(): void {
    if (this.stopObj) {
      // if an existing stop object is passed in, use it to populate autocomplete
      this.stopModel = this.stopObj;
    }
  }

  public addStop(stop: Stop): void {
    this.stopModel = stop;
    if (stop?.stop_id) {
      this.fg?.patchValue(stop);
      this.stopAdded.emit(stop);
    } else {
      this.fg?.patchValue({ stop_id: null });
    }
  }

  public search = (text$: Observable<string>): Observable<Stop[]> => text$.pipe(debounceTime(200),
    distinctUntilChanged(),
    switchMap((term: string) => this.stopService.autocomplete({ stop_name: term, sort: 'stop_name' }).pipe(map((res: Stop[]) => {
      if (term === '') {
        this.fg?.patchValue({ stop_id: null, stop_name: null });
      }
      const self = (this.controlContainer.control?.value as Stop).stop_id;
      const existingIds = this.getSelectorIDs();
      const finalResult = res.filter((x) => {
        const isInChildren =
                !existingIds.includes(x.stop_id) && x.stop_id !== self;

        return isInChildren;
      });

      return finalResult;
    }))));

  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  public formatter = (stop: { stop_id: string; stop_name: string }): string => stop.stop_name ?? '';

  public onBlur(): void {
    if (!(this.fg?.value as Stop)?.stop_id) {
      this.stopModel = undefined;
    }
    this.fg?.controls.stop_id.markAsTouched();
    this.fg?.controls.stop_id.updateValueAndValidity({ onlySelf: true });
  }

  public onFocus(): void {
    this.markAsTouched.emit();
    if ((this.fg?.value as Stop)?.stop_id) {
      this.stopModel = undefined;
      this.fg?.setValue({ stop_id: null, stop_name: null });
    }
  }

  private getSelectorIDs(): string[] {
    return [this.fg?.controls.stop_id.value];
  }

}
