import { Component, Input } from '@angular/core';

import type { Observable, Subscription } from 'rxjs';

/**
 * Component to display a loading dialog when an observable/promise/sub is running
 */
@Component({
  selector: 'mtx-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  standalone: false,
})
export class LoadingDialogComponent {

  /** Promise/observable/sub passed from element to watch */
  @Input() public watch?: Observable<any> | Promise<any> | Subscription | boolean;

  @Input() public displayText = 'Loading...';

}
