import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

/**
 * Pipe for searching an array of object by name
 */
@Pipe({
  name: 'SearchByName',
  standalone: false,
})
export class SearchByNamePipe implements PipeTransform {

  /**
   * Returns sorted and filtered array of objects
   * @param value array of object with a name property
   * @param arg string to match the name property to
   */
  public transform<T extends { name: string }>(value: T[], arg?: string | null): T[] {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!value) {
      return value;
    }

    if (!arg) {
      return value.sort((a, b) => a.name.localeCompare(b.name));
    }

    const nameFilter = arg.toLowerCase();

    const result = value.filter((obj) => {
      const name: string = obj.name ? obj.name : '';
      return name.toLowerCase().includes(nameFilter);
    });

    return result.sort((a, b) => a.name.localeCompare(b.name));
  }

}
