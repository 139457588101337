/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Location } from 'src/app/shared';
import { HTMLEncoder } from '../helpers/html-encoder';

/**
 * Pipe for converting address obj to html or text
 */
@Pipe({
  name: 'formatAddress',
  pure: true,
  standalone: false,
})
export class FormatAddressPipe implements PipeTransform {

  /**
   * Converts address object
   * @param raw UnformattedAddress, address object
   * @param format String text or html
   * @returns Formatted address
   */
  public transform(raw: Location | null | undefined, format: 'html' | 'text'): string {
    if (raw === null || raw === undefined) {
      return 'NO GPS';
    }

    if (format === 'html') {
      return this.formatHtml(raw);
    }

    return this.formatText(raw);
  }

  private formatHtml(raw: Location): string {
    if (raw.address?.country_code) {
      let line1 = raw.address.road_number ? `${raw.address.road_number} ` : '';
      line1 += raw.address.road ? raw.address.road : '';

      let line2 = raw.address.city ? raw.address.city : raw.address.county ? raw.address.county : '';
      line2 += raw.address.state ? line2 !== '' ? `, ${raw.address.state}` : raw.address.state : '';

      line2 += raw.address.zip ? line2 !== '' ? ` ${raw.address.zip}` : raw.address.zip : '';

      line1 = HTMLEncoder.encodeHTML(line1);
      line2 = HTMLEncoder.encodeHTML(line2);

      // verify we have some addr info built
      if (line1.trim() !== '' || line2.trim() !== '') {
        return `${line1} <br/> ${line2}`;
      }
    }

    if (raw.geometry && raw.geometry.coordinates[0] !== 0 && raw.geometry.coordinates[1] !== 0) {
      return `${raw.geometry.coordinates[1]}, ${raw.geometry.coordinates[0]}`;
    }

    return 'NO GPS';
  }

  private formatText(raw: Location): string {
    if (raw.address?.country_code) {
      let line1 = raw.address.road_number ? `${raw.address.road_number} ` : '';
      line1 += raw.address.road ? raw.address.road : '';

      let line2 = raw.address.city ? raw.address.city : raw.address.county ? raw.address.county : '';
      line2 += raw.address.state ? line2 !== '' ? `, ${raw.address.state}` : raw.address.state : '';

      line2 += raw.address.zip ? line2 !== '' ? ` ${raw.address.zip}` : raw.address.zip : '';

      return `${line1} ${line2}`;
    } else if (raw.geometry && raw.geometry.coordinates[0] !== 0 && raw.geometry.coordinates[1] !== 0) {
      return `${raw.geometry.coordinates[1]}, ${raw.geometry.coordinates[0]}`;
    }
    return 'NO GPS';
  }

}
