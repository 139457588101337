import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'personDisplayName',
  standalone: false,
})
export class PersonDisplayNamePipe implements PipeTransform {

  // eslint-disable-next-line max-statements
  public static ToDisplayName(value: PersonNameParts | null | undefined): string {
    if (!value) {
      return '';
    }

    let displayName = '';

    if (value.last_name) {
      displayName = PersonDisplayNamePipe.append(displayName, '', value.last_name);
    }

    if (value.prefix) {
      displayName = PersonDisplayNamePipe.append(displayName, ', ', value.prefix);
    }

    if (value.first_name) {
      displayName = PersonDisplayNamePipe.append(displayName,
        value.prefix ? ' ' : ', ',
        value.first_name);
    }

    if (value.middle_name) {
      displayName = PersonDisplayNamePipe.append(displayName, ' ', value.middle_name);
    }

    if (value.suffix) {
      displayName = PersonDisplayNamePipe.append(displayName, ', ', value.suffix);
    }

    // fallback in this order
    if (displayName === '') {
      displayName = value.organization_identifier || value.id || 'Unknown';
    }

    return displayName;
  }

  private static append(displayName: string, seperator: string, text: string): string {
    if (displayName.length > 0) {
      return displayName + seperator + text;
    }

    return text;
  }

  public transform(value: PersonNameParts | null | undefined): string {
    return PersonDisplayNamePipe.ToDisplayName(value);
  }

}

export interface PersonNameParts {
  suffix: string | null;
  prefix: string | null;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  organization_identifier: string;
  id: string;
}
