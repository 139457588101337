import type { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth.service';
import type { UserOrg } from 'src/app/auth.service';
import { LoggerService } from 'src/app/shared';
import type { AlertMessage } from 'src/app/shared';
import type { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, Inject } from '@angular/core';

/**
 * Modal for changing orgs
 */
@Component({
  selector: 'mtx-change-org-modal',
  templateUrl: './change-org-modal.component.html',
  styleUrls: ['./change-org-modal.component.scss'],
  standalone: false,
})
export class ChangeOrgModalComponent implements OnInit, AfterViewInit {

  @ViewChild('changeOrgInput') public changeOrgInput?: ElementRef;

  /** Displays error/success messages */
  public messages: AlertMessage[] = [];

  /** Observable for loading dropdown options */
  public loadingRequest?: Observable<[UserOrg, UserOrg[]]>;

  /** Organizations users are a part of */
  public organizations: UserOrg[] = [];

  /** Organization search string */
  public orgSearch?: string;

  /** Currently selected org */
  public currentOrg?: UserOrg;

  public constructor(

    /** Modal reference */
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(LoggerService) private readonly loggerService: LoggerService,
    @Inject(AuthService) private readonly authService: AuthService,
  ) {}

  /**
   * Gets orgs
   */
  public ngOnInit(): void {
    this.loadingRequest = forkJoin([
      this.authService.getCurrentOrganization(),
      this.authService.getAllowedOrganizations(),
    ]);

    this.loadingRequest.subscribe({
      next: (res) => {
        this.loadingRequest = undefined;

        // set current org
        this.currentOrg = res[0];
        this.organizations = res[1];
      },
      error: (error) => {
        this.loadingRequest = undefined;
        this.messages = this.loggerService.apiErrorsToDisplayErrors(error);
      },
    });
  }

  public ngAfterViewInit(): void {
    if (this.changeOrgInput?.nativeElement) {
      this.changeOrgInput.nativeElement.focus();
    }
  }

  /**
   * Changes org
   * @param org The organization to switch to
   */
  public switchOrg(org: UserOrg): void {
    if (this.loadingRequest) {
      return;
    }

    this.messages = [];

    this.authService.swapOrg(org);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    // this.router.navigateByUrl('/dashboard');

    if (window.location.pathname.startsWith('/error-pages/')) {
      this.activeModal.close();
    } else {
      const urlNoQPs = window.location.href.split('?')[0];
      window.location.replace(urlNoQPs);
    }
  }

}
