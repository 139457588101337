/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
import type { HttpParameterCodec } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';


class CustomEncoder implements HttpParameterCodec {

  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  public decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  public decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

}

/** Service available in root */
@Injectable({
  providedIn: 'root',
})
export class ParamSerializer {

  /**
   * Converts object into url params
   * @param value Takes filters
   * @param params HttpParams
   * @returns serialized params to be added to url
   *
   */
  public serialize(val: Record<string, any>, params?: HttpParams | null): string {
    if (params === null || params === undefined) {
      params = new HttpParams({ encoder: new CustomEncoder() });
    }

    if (!val) {
      return '';
    }

    if (typeof val === 'object') {
      params = this.serializeObject(val, params, '');
    }

    return params.toString();
  }

  /**
   * Converts object into url params
   * @param value Takes object filters
   * @param params HttpParams
   * @param prefix string
   * @returns serialized params to be added to url
   */
  private serializeObject(obj: Record<string, any>,
    params: HttpParams,
    prefix?: string): HttpParams {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const myPrefix = prefix ? `${prefix}.${key}` : key;
        if (Array.isArray(obj[key])) {
          params = this.serializeArray(obj[key], params, myPrefix);
        } else if (typeof obj[key] === 'boolean') {
          params = params.append(myPrefix, obj[key]);
        } else if (typeof obj[key] === 'string' && obj[key] !== '') {
          params = params.append(myPrefix, obj[key]);
        } else if (typeof obj[key] === 'number') {
          params = params.append(myPrefix, obj[key]);
        } else if (obj[key] === null || obj[key] === undefined) {
          // do nothing
        } else if (typeof obj[key] === 'object') {
          params = this.serializeObject(obj[key] as Record<string, unknown>, params, myPrefix);
        }
      }
    }

    return params;
  }


  /**
   * Converts object into url params
   * @param value Takes array filters
   * @param params HttpParams
   * @param prefix string
   * @returns serialized params to be added to url
   */
  private serializeArray(arr: any[],
    params: HttpParams,
    prefix: string): HttpParams {
    for (const val of arr) {
      if (Array.isArray(val)) {
        params = this.serializeArray(val, params, prefix);
      } else if (typeof val === 'boolean' ||
        typeof val === 'number' ||
        (typeof val === 'string' && val !== '')) {
        params = params.append(prefix, val);
      } else if (val === null || val === undefined) {
        // do nothing
      } else if (typeof val === 'object') {
        params = this.serializeObject(val as Record<string, unknown>, params, prefix);
      }
    }

    return params;
  }

}

