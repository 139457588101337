/* eslint-disable max-depth */
import { Component, Input } from '@angular/core';
import type { AlertMessage } from './alert-message';
import type { RFCAlertMessage } from './rfc-alert-message';

/**
 * Component that takes information and displays alert messages on the page
 */
@Component({
  selector: 'mtx-alert-display',
  template: `
  <div *ngIf="basicAlerts.length > 0; else rfcAlertsBlock">
    <ngb-alert
    [type]="'danger'"
    [dismissible]="false"
    *ngIf="countByType('danger') > 0"
    >
      <ul>
        <li *ngFor="let alert of basicAlerts | alertType: 'danger'">
          {{ alert.msg }}
        </li>
      </ul>
    </ngb-alert>

    <ngb-alert
    [type]="'success'"
    [dismissible]="false"
    *ngIf="countByType('success') > 0"
    >
    <ul>
      <li *ngFor="let alert of basicAlerts | alertType: 'success'">
        {{ alert.msg }}
        </li>
      </ul>
    </ngb-alert>

    <ngb-alert
    [type]="'warning'"
    [dismissible]="false"
    *ngIf="countByType('warning') > 0"
    >
    <ul>
      <li *ngFor="let alert of basicAlerts | alertType: 'warning'">
        {{ alert.msg }}
      </li>
    </ul>
  </ngb-alert>
</div>

<ng-template #rfcAlertsBlock>
<ngb-alert
    [type]="'danger'"
    [dismissible]="false"
    *ngIf="countByType('danger') > 0"
    >
    <div *ngFor="let alert of rfcAlerts">
      <div><b>{{alert.title}}</b></div>
      <ul>
        <li *ngFor="let msg of alert.messages | alertType: 'danger'">
          {{ msg.msg }}
        </li>
      </ul>
    </div>
    </ngb-alert>

    <ngb-alert
    [type]="'success'"
    [dismissible]="false"
    *ngIf="countByType('success') > 0"
    >
    <div *ngFor="let alert of rfcAlerts">
      <div><b>{{alert.title}}</b></div>
      <ul>
        <li *ngFor="let msg of alert.messages | alertType: 'success'">
          {{ msg.msg }}
        </li>
      </ul>
    </div>
    </ngb-alert>

    <ngb-alert
    [type]="'warning'"
    [dismissible]="false"
    *ngIf="countByType('warning') > 0"
    >
    <div *ngFor="let alert of rfcAlerts">
      <div><b>{{alert.title}}</b></div>
      <ul>
        <li *ngFor="let msg of alert.messages | alertType: 'warning'">
          {{ msg.msg }}
        </li>
      </ul>
    </div>
  </ngb-alert>
</ng-template>
  `,
  styles: [
    ':host::ng-deep .alert, .alert-danger { border-radius: 0px; }',
    ':host::ng-deep .alert ul { padding-bottom: 0px; margin-bottom: 0px; }',
  ],
  standalone: false,
})
export class AlertDisplayComponent {

  /** Displays error/success messages */
  @Input() public alerts: (AlertMessage[] | RFCAlertMessage[]) = [];

  public get basicAlerts(): AlertMessage[] {
    return this.alerts.length > 0 && 'type' in this.alerts[0] ? this.alerts as AlertMessage[] : [];
  }

  public get rfcAlerts(): RFCAlertMessage[] {
    return this.alerts.length > 0 && 'title' in this.alerts[0] ? this.alerts as RFCAlertMessage[] : [];
  }

  /**
   * Closes displayed alerts
   * @param alert AlertMessage
   */
  public closeAlert(alert: AlertMessage | RFCAlertMessage): void {
    const index = (this.alerts as AlertMessage[]).indexOf(alert as AlertMessage);
    this.alerts.splice(index, 1);
  }

  /**
   * Counts errors of that type
   * @param type string of type
   * @returns number of types
   */
  public countByType(type: string): number {
    let count = 0;
    if (this.alerts.length > 0) {
      // if array is of type AlertMessage
      if ('type' in this.alerts[0]) {
        for (const alert of this.basicAlerts) {
          if (alert.type === type) {
            count++;
          }
        }
      } else {
        // if array is of type RFCAlertMessage
        for (const alert of this.rfcAlerts) {
          for (const msg of alert.messages) {
            if (msg.type === type) {
              count++;
            }
          }
        }
      }
    }

    return count;
  }

}
