import type { OnChanges } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import type { UserPrefs } from 'src/app/user/user-preferences/shared/user-prefs';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'mtx-date',
  templateUrl: './mtx-date.component.html',
  styleUrls: ['./mtx-date.component.scss'],
  standalone: false,
})
export class MtxDateComponent implements OnChanges {

  @Input() public inputDate?: string | null | undefined = '';

  @Input() public includeDay?: boolean | null | undefined = true;

  @Input() public includeMonth?: boolean | null | undefined = true;

  @Input() public inputStyles: any;

  public formattedDate = '';

  public userPrefsLoadingRequest$?: Observable<UserPrefs>;

  private currentUserPrefs: UserPrefs | undefined;

  public constructor(
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(LoggerService) private readonly loggerService: LoggerService,
  ) { }

  public ngOnChanges(): void {
    this.getSettings();
    const inputMoment = moment(this.inputDate, true);
    if (inputMoment.isValid()) {
      this.formattedDate = inputMoment.format(this.getFormatToUse());
    }
  }

  private getFormatToUse(): string {
    let formatToUse = this.currentUserPrefs?.date_format ?? 'MM/DD/YYYY';

    if (!this.includeDay) {
      formatToUse = formatToUse.replace(/([/,-]DD)|(DD[/,-])/, '');
    }

    if (!this.includeMonth) {
      formatToUse = formatToUse.replace(/([/,-]MM)|(MM[/,-])/, '');
    }

    return formatToUse;
  }

  private getSettings(): void {
    this.userPrefsLoadingRequest$ = this.authService.getCurrentUserPrefs();
    this.userPrefsLoadingRequest$.subscribe({
      next: (res: UserPrefs) => {
        this.userPrefsLoadingRequest$ = undefined;
        this.currentUserPrefs = res;
      },
      error: (error: any) => {
        this.loggerService.apiErrorsToDisplayErrors(error);
        this.userPrefsLoadingRequest$ = undefined;
      },
    });
  }

}
