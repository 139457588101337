import { UntypedFormControl } from '@angular/forms';
import type { Observable } from 'rxjs';
import { first, from } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import type { AlertMessage } from 'src/app/shared';
import { LoggerService,
  SubmittableFormGroup } from 'src/app/shared';
import type { UserNotification } from './shared/user-notification';
import { UserNotificationService } from './shared/user-notification.service';
import { AuthService } from 'src/app/auth.service';
import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { PermissionList } from 'src/app/shared/classes/permission-list';

/**
 * Modal for editting notifications
 */
@Component({
  selector: 'mtx-notification-edit-modal',
  templateUrl: './notification-edit-modal.component.html',
  styleUrls: ['./notification-edit-modal.component.scss'],
  standalone: false,
})
export class NotificationEditModalComponent implements OnInit {

  /** Displays error/success messages */
  public messages: AlertMessage[] = [];


  /** Holds mobile notifications */
  public emailNotifs: string[] = [];

  public mobileNotifs: string[] = [];

  /** If mobile is enabled */
  public mobileEnabled = true;

  /** Formgroup for notification form */
  public notifForm: SubmittableFormGroup;

  public userInfo?: {
    first_name: string;
    last_name: string;
    id: string;
    email: string;
    phone: string;
    email_verified: boolean;
    phone_verified: boolean;
  };

  /** Hold notifications */
  private notifications: UserNotification[] = [];

  /** Observable for creating notification requests */
  private updateRequest?: Observable<UserNotification[]>;

  /** Observable to get user */
  private loadingRequest?: Observable<UserNotification[]>;

  public constructor(

    /** Modal reference */
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(LoggerService) private readonly loggerService: LoggerService,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(UserNotificationService) private readonly userNotificationService: UserNotificationService,
  ) {
    this.notifForm = new SubmittableFormGroup({
      notifications: new UntypedFormControl([]),
    });
  }

  /**
   * Initializes notification form
   */
  public ngOnInit(): void {
    from(this.authService.checkUserHasAtLeastOnePermission([PermissionList.AVL_VIEW_EVENTS])).pipe(first())
      .subscribe((hasPermission: boolean) => {
        if (hasPermission) {
          this.userInfo = this.authService.getUserInfo();

          this.loadingRequest = this.userNotificationService.search();

          this.loadingRequest.subscribe((res) => {
            this.loadingRequest = undefined;

            this.notifications = res;
            this.emailNotifs = res
              .filter(item => item.send_email)
              .map(item => item.event_type);
            this.mobileNotifs = res
              .filter(item => item.send_mobile)
              .map(item => item.event_type);
          });
        } else {
          this.activeModal.dismiss();
        }
      });
  }

  /**
   * Makes request to have notification saved
   */
  public save(): void {
    if (this.updateRequest) {
      return;
    }

    this.notifForm.submitted = true;
    this.messages = [];

    if (!this.notifForm.valid) {
      this.messages = this.loggerService.mapFormGroupToErrors(this.notifForm);
      return;
    }

    this.updateRequest = this.userNotificationService.update(this.notifications);

    this.updateRequest.subscribe({
      next: () => {
        this.activeModal.close();
      },
      error: (err: any) => {
        this.messages = this.loggerService.apiErrorsToDisplayErrors(err);
      },
      complete: () => {
        this.updateRequest = undefined;
        this.notifForm.submitted = false;
      },
    });
  }

  /**
   * Toggles notifications within formgroup based on passed value
   * @param val Notification value
   */
  public toggleEmailNotification(val: string): void {
    const notif = this.notifications.find(item => item.event_type === val);

    if (notif) {
      this.notifications[this.notifications.indexOf(notif)].send_email =
        !notif.send_email;
    } else {
      this.notifications.push({
        event_type: val,
        send_email: true,
        send_mobile: false,
      });
    }
  }

  /**
   * Toggles mobile notifications within formgroup based on passed value
   * @param val Mobile notification value
   */
  public toggleMobileNotification(val: string): void {
    const notif = this.notifications.find(item => item.event_type === val);

    if (notif) {
      this.notifications[this.notifications.indexOf(notif)].send_mobile =
        !notif.send_mobile;
    } else {
      this.notifications.push({
        event_type: val,
        send_email: false,
        send_mobile: true,
      });
    }
  }

  public checkEmailNotifications(): boolean {
    return this.notifications.some(x => x.send_email);
  }

  public checkMobileNotifications(): boolean {
    return this.notifications.some(x => x.send_mobile);
  }

}
