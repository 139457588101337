import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { GeocoderService } from './geocoding/geocoder.service';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { GeofenceLayerDirective } from './ngx-leaflet-exts/geofence-layer.directive';
import { TileLayerDirective } from './ngx-leaflet-exts/tile-layer.directive';
import { GenericControlsLayerDirective } from './ngx-leaflet-exts/generic-controls.directive';
import { SingleShapeEditorDirective } from './ngx-leaflet-exts/single-shape-editor.directive';
import { EventLayerDirective } from './ngx-leaflet-exts/event-layer.directive';
import { VehicleLayerDirective } from './ngx-leaflet-exts/vehicle-layer.directive';
import { HEREv8RoutingService } from './routing/lrm-here';
import { OverlayLayerDirective } from './ngx-leaflet-exts/overlay-layer.directive';

/**
 * Module that contains mototrax map components
 */
@NgModule({
  declarations: [
    GeofenceLayerDirective,
    TileLayerDirective,
    GenericControlsLayerDirective,
    SingleShapeEditorDirective,
    EventLayerDirective,
    VehicleLayerDirective,
    OverlayLayerDirective,
  ],
  imports: [
    CommonModule,
    LeafletModule,
  ],
  exports: [
    GeofenceLayerDirective,
    TileLayerDirective,
    GenericControlsLayerDirective,
    SingleShapeEditorDirective,
    EventLayerDirective,
    VehicleLayerDirective,
    OverlayLayerDirective,
  ],
  providers: [
    GeocoderService,
    HEREv8RoutingService,
  ],
})
export class MapModule {

  public static forRoot(): ModuleWithProviders<MapModule> {
    return {
      ngModule: MapModule,
      providers: [],
    };
  }

}
