import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import pkgInfo from '../../../../../package.json';

/**
 * Component for displaying footer view
 */
@Component({
  selector: 'mtx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: false,
})
export class FooterComponent implements OnInit {

  /** App version number */
  public appVersion = pkgInfo.version;

  public ngOnInit(): void {
    document.querySelector('#appVersion')?.addEventListener('click', (evt) => {
      if ((evt as CustomEvent).detail === 5) {
        window.sessionStorage.setItem('debugLogs', 'true');
      }
    });
  }

}
