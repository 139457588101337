import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import type { UserOrg } from 'src/app/auth.service';
import { LoggerService } from 'src/app/shared';
import type { SubmittableFormGroup } from 'src/app/shared';
import { UserOrganizationService } from '../change-org-modal/shared/user-organization.service';
import type { UserPrefs } from '../user-preferences/shared/user-prefs';
import { UserPreferencesService } from './shared/user-preferences.service';
import { UserPreferencesFormComponent } from './user-preferences-form.component';
import type { HttpErrorResponse } from '@angular/common/http';
import { OrganizationProfileService } from 'src/app/admin/organization-profiles/shared/organization-profile.service';
import type { UserProfileDescription } from '../user-profile/shared/user-profile';

/**
 * Component for editting the logged in user account
 */
@Component({
  selector: 'mtx-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  standalone: false,
})
export class UserPreferencesComponent
  extends UserPreferencesFormComponent
  implements OnInit {

  // expose ui
  /** Formgroup for user form */
  public override userForm: SubmittableFormGroup = this.formUserFromObj();

  /** Boolean for removing full header when user isn't logged in */
  public override userAuth = true;

  public orgOptions: UserOrg[] = [];

  public timezoneOptions: string[] = [];

  public localeOptions: string[] = [];

  public dateFormatOptions: string[] = [];

  public userInfo;

  public userProfile?: UserProfileDescription;

  // internal
  /** Observable for loading dropdown options */
  private loadingRequest?: Observable<[UserPrefs, UserOrg[], string[], UserProfileDescription]>;

  /** Observable for updating the selected user */
  private updateRequest?: Observable<UserPrefs>;

  /**
   * Sets title and super
   */
  private readonly orgOptionsSub?: Subscription;

  public constructor(

    /** User service reference */
    @Inject(UserPreferencesService) protected override userPreferencesService: UserPreferencesService,

    /** Organization Service reference */
    @Inject(UserOrganizationService) protected organizationService: UserOrganizationService,

    /** Organization Service reference */
    @Inject(OrganizationProfileService) protected organizationProfileService: OrganizationProfileService,

    /** Logger service reference */
    @Inject(LoggerService) protected override loggerService: LoggerService,

    /** Router reference */
    @Inject(AuthService) private readonly authService: AuthService,
  ) {
    super(userPreferencesService, loggerService);
    this.editSelf = true;
    this.title = 'User Preferences';
    this.userInfo = this.authService.getUserInfo();
  }

  /**
   * Gets orgs
   */
  public ngOnInit(): void {
    this.loadingRequest = forkJoin([
      this.authService.getCurrentUserPrefs(),
      this.authService.getAllowedOrganizations(),
      this.organizationProfileService.getTimezones(),
      this.authService.getUserProfile(),
    ]);

    this.loadingRequest.subscribe({
      next: (res) => {
        this.loadingRequest = undefined;
        const fg = this.formUserFromObj(res[0]);
        this.userForm = fg;
        this.orgOptions = res[1];
        this.timezoneOptions = res[2];
        this.userProfile = res[3];
        this.localeOptions = ['en-US'];
        this.dateFormatOptions = ['MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD'];
      },
      error: (error: HttpErrorResponse) => {
        this.loadingRequest = undefined;
        if (error.status === 403) {
          this.messages.push({ type: 'danger',
            msg: 'No preferences to change when not a member of any organizations.' });
        } else {
          this.messages = this.loggerService.apiErrorsToDisplayErrors(error);
        }
      },
    });
  }

  /**
   * Updates selected user when form is submitted
   */
  public override save(): void {
    if (this.updateRequest ?? this.loadingRequest) {
      return;
    }

    this.messages = [];
    this.userForm.submitted = true;

    if (!this.userForm.valid) {
      this.messages = this.loggerService.mapFormGroupToErrors(this.userForm);
      return;
    }

    const userCopy = this.objFromFormGroup(this.userForm);

    this.updateRequest =
      this.userPreferencesService.updateUserPreferences(userCopy);
    this.updateRequest.subscribe({
      next: (user) => {
        this.updateRequest = undefined;
        this.authService.clearCache();
        this.messages.push({
          type: 'success',
          msg: 'User preferences were updated.',
        });
        const fg = this.formUserFromObj(user);

        this.userForm = fg;
        this.userForm.submitted = false;
      },
      error: (err: any) => {
        this.messages = this.loggerService.apiErrorsToDisplayErrors(err);
        this.updateRequest = undefined;
      },
    });
  }

}
