import { DomSanitizer } from '@angular/platform-browser';
import type { SafeHtml } from '@angular/platform-browser';
import { UntypedFormControl } from '@angular/forms';
import type { Observable } from 'rxjs';
import type { SubmittableFormGroup } from '../..';
import { LoggerService, VehicleService } from '../..';
import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';

interface Group {
  id: string;
  name: string;
}

interface Vehicle {
  id: string;
  name: string;
  group: Group;
}

/**
 * Component for dropdowns for filtering by group and vehicle
 */
@Component({
  selector: 'mtx-filter-vehicle-by-group',
  templateUrl: './filter-vehicle-by-group.component.html',
  styleUrls: ['./filter-vehicle-by-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class FilterVehicleByGroupComponent implements OnChanges {

  /** Form group passed from element */
  @Input() public tgtFormGroup?: SubmittableFormGroup;


  /** Whether to change to multiple vehicles */
  @Input() public multipleVehicles?: boolean;


  /** Mode of selection */
  @Input() public mode = 'SELECT';


  /** Formcontrol of vehicle */
  @Input() public vehicleFormControlName = 'vehicle.id';

  /** Emitter for changes */
  @Output() public vehicleSelected: EventEmitter<void> =
    new EventEmitter<void>();

  /** Array of group options */
  public groupOptions: Group[] = [];

  /** Array of vehicles options */
  public vehicleOptions: Vehicle[] = [];

  /** Input for vehicle searching */
  public vehicleSearch?: string;

  /** Boolean for is vehicle is required */
  public vehicleSelectionIsRequired = false;

  public groupSelectedId = '';

  public filteredVehicleIds: string[] = [];

  public selectedVehicles: string[] = [];

  /** Observable for loading groups/vehicles */
  private loadingRequest?: Observable<Vehicle[]>;

  /**
   * Clears and reloads options
   */
  public constructor(@Inject(VehicleService) private readonly vehicleService: VehicleService,
    @Inject(LoggerService) private readonly loggerService: LoggerService,
    @Inject(ChangeDetectorRef) private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer) {
    this.groupOptions = [];
    this.vehicleOptions = [];
    this.loadOptions();

    // fix binding issue
    this.filterVehicles = this.filterVehicles.bind(this);
  }

  /**
   * Watches for changes
   * @param changes SimpleChanges
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.tgtFormGroup.currentValue !== changes.tgtFormGroup.previousValue
    ) {
      const validator =
        this.tgtFormGroup?.controls[this.vehicleFormControlName]?.validator;

      if (validator) {
        const validationResult = validator(new UntypedFormControl());
        this.vehicleSelectionIsRequired = validationResult?.required;
      } else {
        this.vehicleSelectionIsRequired = false;
      }
    }
  }

  /**
   * Fires and changes filteres when vehicle is changed
   */
  public vehicleSelectionChanged(): void {
    if (this.selectedVehicles.includes('')) {
      this.tgtFormGroup?.controls[this.vehicleFormControlName]?.setValue(this.filteredVehicleIds);
    } else {
      this.tgtFormGroup?.controls[this.vehicleFormControlName]?.setValue(this.selectedVehicles);
    }
    this.vehicleSelected.emit();
  }

  /**
   * Sets currently selected vehicle to filters
   * @param id Number ID of vehicle
   */
  public toggleSelectedVehicle(id: string): void {
    const opts: string[] =
      this.tgtFormGroup!.value[this.vehicleFormControlName];
    const idx = opts.indexOf(id);
    if (idx > -1) {
      opts.splice(idx, 1);
    } else {
      opts.push(id);
    }

    this.tgtFormGroup?.controls[this.vehicleFormControlName]?.setValue(opts, {
      emitEvent: true,
    });
    this.vehicleSelected.emit();
  }

  public onGroupChanged(): void {
    this.filteredVehicleIds = this.groupFilter().map(x => x.id);

    this.tgtFormGroup?.controls[this.vehicleFormControlName]?.setValue(this.multipleVehicles ? this.filteredVehicleIds : '');

    if (this.mode === 'CHECK') {
      this.tgtFormGroup?.controls[this.vehicleFormControlName]?.setValue([]);
    }

    this.vehicleSelected.emit();
  }

  public filterVehicles(vehicle: Vehicle): boolean {
    const search = this.vehicleSearch;
    const gid = this.groupSelectedId;
    if (search) {
      if (!vehicle.name.toLowerCase().includes(search.toLowerCase())) {
        return false;
      }
    }

    if (gid) {
      if (vehicle.group.id !== gid) {
        return false;
      }
    }

    return true;
  }

  public groupFilter(): Vehicle[] {
    return this.vehicleOptions.filter(x => x.group.id === this.groupSelectedId);
  }


  /**
   * Refreshes dropdown options
   */
  private loadOptions(): void {
    if (this.loadingRequest) {
      return;
    }

    if (this.tgtFormGroup) {
      if (this.multipleVehicles) {
        this.tgtFormGroup?.controls[this.vehicleFormControlName].setValue([]);
      } else {
        this.tgtFormGroup?.controls[this.vehicleFormControlName].setValue('');
      }
    }

    this.loadingRequest = this.vehicleService.getOptions();

    /*
     * this.loadingRequest = of({
     *     count: 2,
     *     length: 2,
     *     data: [
     *         { id: 1, name: 'V1', group: { id: 1, name: 'g1' }},
     *         { id: 2, name: 'v2', group: { id: 2, name: 'G2' }},
     *     ]
     * } as PagedResponse<Vehicle>)
     */

    this.loadingRequest.subscribe({
      next: (result: Vehicle[]) => {
        this.loadingRequest = undefined;
        this.vehicleOptions = result;

        const groups: Group[] = [];
        result.forEach((vehicle) => {
          if (groups.findIndex(grp => grp.id === vehicle.group.id) < 0) {
            groups.push(vehicle.group);
          }
        });

        this.groupOptions = groups.sort((a, b) => a.name.localeCompare(b.name));

        this.changeDetectorRef.markForCheck();
      },
      error: (error: Error) => {
        this.loggerService.logError(error);
        this.loadingRequest = undefined;
      },
    });
  }

  /**
   * Sanitizes HTML for autocomplete
   * @param data SafeHtml
   * @returns Sanitized html
   */
  private readonly autocompleListFormatter = (data: Vehicle): SafeHtml => {
    const html = `<span>${data.name}</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  };

}
