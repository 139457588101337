import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

/*
 * Converts value from 1 speed unit to another
 * Usage:
 *   value | from:unit | to: unit
 * Example:
 *   {{ 10 | speedConverter:kph:mph }}
 *   formats to: 6.21371
 */
@Pipe({
  name: 'speedConverter',
  standalone: false,
})
export class SpeedConverterPipe implements PipeTransform {

  public transform(value: number, from: SpeedUnit, to: SpeedUnit): number {
    if (from === 'kph' && to === 'mph') {
      return value * 0.621371;
    } else if (from === 'mph' && to === 'kph') {
      return value * 1.60934;
    }
    throw new Error(`unsupported conversion from ${from} to ${to}`);
  }

}

export type SpeedUnit = 'kph' | 'mph';
