import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import type { Subscription } from 'rxjs';
import { FileDownloadService } from '../..';
import type { MotoTraxFile } from '../../generic/file';

/**
 * Component of modal for confirming/canceling actions
 */
@Component({
  selector: 'mtx-download-progress-modal',
  template: `
    <div id="confirmationModal">
      <div class="modal-header">
        <span
          class="modal-title pull-left"
          [translate]="'shared.downloading.file-download'"
        ></span>
        <button
          type="button"
          class="btn-close pull-right"
          aria-label="Close"
          (click)="activeModal.dismiss('cancel')"
        >
        </button>
      </div>
      <div class="modal-body">
        <div class="container text-center">
          <div *ngIf="status === 'initiating'">
            <div
              class="spinner-grow"
              style="width: 3rem; height: 3rem;"
              role="status"
            >
              <span
                class="sr-only"
                [translate]="'shared.downloading.initiating'"
              ></span>
            </div>
            <div>
              <span [translate]="'shared.downloading.initiating'"
                >Downloading...</span
              >
            </div>
          </div>
          <div *ngIf="status === 'inprogress'">
            <div class="spinner-border" role="status">
              <span
                class="sr-only"
                [translate]="'shared.downloading.downloading'"
                >Downloading...</span
              >
            </div>
            <div>
              <span [translate]="'shared.downloading.downloading'"
                >Downloading...</span
              >
            </div>
          </div>
          <div *ngIf="status === 'complete'">
            <h4 [translate]="'shared.downloading.complete'"></h4>
            <div
              [translate]="'shared.downloading.check-browser-downloads'"
            ></div>
          </div>
          <div
            *ngIf="status === 'error'"
            [translate]="'shared.downloading.error'"
          ></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="status === 'initiating' || status === 'inprogress'"
          id="btnCMCancel"
          type="button"
          class="btn btn-sm"
          (click)="activeModal.dismiss('cancel')"
          [translate]="'general.cancel'"
        ></button>

        <button
          *ngIf="status === 'complete' || status === 'error'"
          id="btnCMCancel"
          type="button"
          class="btn btn-sm"
          (click)="activeModal.close('ok')"
          [translate]="'general.close'"
        ></button>
      </div>
    </div>
  `,
  styleUrls: ['./download-progress-modal.component.scss'],
  standalone: false,
})
export class DownloadProgressModalComponent implements OnInit, OnDestroy {

  /** Message passed from element */
  @Input() public url?: string;

  public status: 'complete' | 'error' | 'initiating' | 'inprogress' = 'initiating';

  private dlRequest?: Subscription;


  public constructor(@Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(FileDownloadService) private readonly dlService: FileDownloadService) {}

  public ngOnInit(): void {
    if (!this.url) {
      this.status = 'error';
      return;
    }

    this.dlRequest = this.dlService.download(this.url).subscribe({
      next: (res: MotoTraxFile) => {
        this.saveAs(res.data, res.name);
        this.status = 'complete';
        this.activeModal.close('ok');
      },
      error: () => {
        this.status = 'error';
      },
      complete: () => {
        this.dlRequest = undefined;
      },
    });
  }

  public ngOnDestroy(): void {
    this.dlRequest?.unsubscribe();
  }

  private saveAs(blob: Blob, name = 'download'): void {
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, name);
    } else {
      const a = document.createElement('a');
      a.download = name;
      a.rel = 'noopener';
      a.href = URL.createObjectURL(blob);
      window.document.body.appendChild(a);
      a.click();
      window.document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    }
  }

}
